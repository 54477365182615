import { Link } from "gatsby"
import React from "react"

const SimpleInternalButton = ({ to, twProps, title }) => {
  return (
    <Link
      to={to}
      className={`${twProps} text-white text-center font-medium py-2 px-4 rounded text-base flex justify-center`}
    >
      {title}
    </Link>
  )
}

const SimpleExternalButton = ({ to, twProps, title }) => {
  return (
    <a
      href={to}
      className={`${twProps} text-white text-center font-medium py-2 px-4 rounded text-base flex justify-center`}
    >
      {title}
    </a>
  )
}

export { SimpleInternalButton, SimpleExternalButton }
