import React from "react"
import Layout from "../components/layout"
import ProjectCard from "../components/UI/ProjectCard"
import { graphql } from "gatsby"

export default function Home({ data }) {
  console.log(data)
  return (
    <Layout>
      <div className="flex justify-center items-center flex-col w-3/4 self-center mb-4 text-center">
        <h1 className="my-4">
          Hi there, I'm{" "}
          <span className="text-transparent bg-gradient-to-r from-gray-600 to-gray-900 bg-clip-text">
            Chris Schaumloeffel.
          </span>
        </h1>
        <p className="font-extralight my-4">
          My passions are{" "}
          <span className="text-pink-400 font-medium">
            web and app development
          </span>
          , <span className="text-green-400 font-medium">finance</span> and{" "}
          <span className="text-blue-400 font-medium">writing</span>.
        </p>
        <p className="font-extralight">
          I share my experiences across projects on this site to help you on
          your own journey.
        </p>
        <p className="font-extralight">
          If you find something interesting, have a question or just want to get
          in touch, please hit me up!
        </p>
      </div>
      <div className="clip-path-slant-down-right bg-gradient-to-b from-pink-400 to-pink-600 h-14" />
      <div className="w-full p-4 -mt-12">
        <h2>Web and app development</h2>
        <div className="">
          {data.allMdx.nodes.map((project, index) => {
            return project.frontmatter.category === "development" ? (
              <ProjectCard key={index} data={project} />
            ) : null
          })}
        </div>
      </div>
      <div className="clip-path-slant-down-right bg-gradient-to-b from-green-400 to-green-600 h-14 -mt-12" />
      <div className="h-52 -mt-12 p-4">
        <h2>Finance</h2>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(filter: { frontmatter: { intro: { eq: true } } }) {
      nodes {
        frontmatter {
          category
          project
          title
          link
          cover {
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED)
            }
          }
        }
        excerpt
      }
    }
  }
`
