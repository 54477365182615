import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { SimpleExternalButton, SimpleInternalButton } from "../Buttons"

const ProjectCard = ({ data }) => {
  const articles = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          frontmatter: {
            project: { eq: "thesimplecryptofund" }
            intro: { eq: false }
          }
        }
        limit: 3
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        nodes {
          excerpt
          frontmatter {
            title
            date
          }
        }
      }
    }
  `)
  const cover = getImage(data.frontmatter.cover)
  return (
    <div className="flex flex-row m-4 p-4 bg-gradient-to-br from-gray-50 to bg-gray-100 rounded-xl shadow-md">
      <div className="w-1/2 mx-4 flex flex-col">
        <h3>{data.frontmatter.title}</h3>
        <GatsbyImage image={cover} alt={data.frontmatter.coverCredit} />
      </div>
      <div className="w-1/2 mx-4 flex flex-col items-center justify-center">
        <div className="h-8" />
        <p>{data.excerpt}</p>
        <div className="w-full">
          <h3>What's happening?</h3>
          {articles.allMdx.nodes.map((article, index) => {
            console.log(article)
            return (
              <p key={index} className="text-sm font-normal">
                {article.frontmatter.title}
              </p>
            )
          })}
        </div>
        <div className="mt-auto flex justify-around w-full">
          <SimpleInternalButton
            to={`/projects/${data.frontmatter.project}/intro`}
            title="Learn more"
            twProps="border border-pink-700 text-pink-700 w-1/3"
          />
          <SimpleExternalButton
            to={data.frontmatter.link}
            title="View project"
            twProps="bg-pink-700 border border-pink-700 w-1/3"
          />
        </div>
      </div>
    </div>
  )
}

export default ProjectCard
